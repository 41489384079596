import {Component, render} from "preact";
import {createPortal} from "preact/compat";

export default class Iframe extends Component {
    populateIframe () {
        if (this.needsPopulate && this.canPopulate) {
            if (this.iframe && this.iframe.contentDocument && this.iframe.contentDocument.readyState === 'complete') {
                const doc = this.iframe.contentDocument;
                if (doc.body !== this.portalParent) {
                    doc.documentElement.replaceChild(this.portalParent, doc.body);
                }
                this.needsPopulate = false;
            } else {
                setTimeout(this.populateIframe.bind(this), 0);
            }
        }
    }
    componentDidMount () {
        this.canPopulate = true;
        this.populateIframe();
    }
    componentDidUnmount () {
        this.canPopulate = false;
    }
    render() {
        // keeping the old portalParent prevents flicker
        this.portalParent = this.portalParent || document.createElement('body');
        this.portal = createPortal(this.props.children, this.portalParent);
        this.needsPopulate = true;
        setTimeout(this.populateIframe.bind(this), 0);
        return <>
            {this.portal}
            {<iframe class={this.props.class} ref={el => this.iframe = el} />}
        </>;
    }
}