import {usePatch} from './store.js';
import css from './css.js';
import {SpinSubmit, apiToStore} from './api.js';

export default () => {
    const [patch, session, login] = usePatch('session', 'login');
    const fields = {};
    const apiCall = (args) => apiToStore(patch, 'login', 'POST', 'v1/login', {...args, csrf: session.csrf, onSuccess: () => patch({historyReplace: ''})});

    const submit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const email = fields.email.value;
        const password = fields.password.value;
        if (email && password) {
            apiCall({body: {email, password}});
        }
    };
    return (
        <div>
            <h2>Please log in below</h2>
            <form onSubmit={submit}>
                <label class={css.section}>
                    Email:<br/>
                    <input type="email" defaultValue="" autoFocus required ref={el => fields.email = el}/>
                </label>
                <label class={css.section}>
                    Password:<br/>
                    <input type="password" defaultValue="" pattern=".{8,}" ref={el => password = el}/>
                </label>
                <SpinSubmit fetch={login} label="Log in"/>
            </form>
        </div>
    );
};
