import {useLayoutEffect, useEffect} from 'preact/compat';
import Link from './Link.js';
import LogIn from './Login.js';
import LogOut from './Logout.js';
import Game, {Welcome, Create, Join, Instructions, Invite, CreditsPage, Missing} from './Game.js';
import css, {cssCode} from './css.js';
import {usePatch} from './store.js';
import {apiToStore, SpinLoad} from './api.js';
import layouts from './layouts.js';

const FileNotFound = () => <layouts.CenteredWhite><header class={css.centeredWhiteHeadline}>File Not Found</header></layouts.CenteredWhite>;

const slugToComponent = new Map([
    ['login', LogIn],
    ['logout', LogOut],
    ['welcome', Welcome],
    ['join', Join],
    ['missing', Missing],
    ['create', Create],
    ['game', Game],
    ['instructions', Instructions],
    ['invite', Invite],
    ['credits', CreditsPage],
    ['break', Game], // this needs the game to be loaded so start with Game
    ['done', Game], // this needs the game to be loaded so start with Game
    ['', Welcome], // "home"/"index"
]);

// main site template
const CurrentSlugComponent = ({inner}) => {
    const [patch, slug] = usePatch('slug');
    const SlugComponent = slugToComponent.get(slug) || FileNotFound;
    return <SlugComponent/>;
}


export default (props) => {
    const [patch, csrf, whoami] = usePatch('csrf', 'whoami');
    const fetch = () => apiToStore(patch, 'whoami', 'GET', 'v1/whoami');
    const needFetch = !csrf && (!whoami || whoami.status === 'idle' || whoami.status === 'error');
    useEffect(() => {
        if (needFetch) {
            fetch();
        }
    });
    return <>
        <style>{cssCode}</style>
        <SpinLoad fetch={whoami} retry={fetch} success={CurrentSlugComponent}/>
    </>;
};