import css from './css.js';
import Spinner from './Spinner.js';

// returns a promise. resolves iff response.ok
export const api = async (method, endpoint, {csrf, body, validator}) => {
    const apiPath = `/api/${endpoint}`;
    const fetchArgs = {
        method,
        headers: {},
    }
    if (csrf) {
        fetchArgs.headers['X-Csrf-Token'] = csrf;
    }
    if (body != null && body !== '') {
        fetchArgs.headers['Content-Type'] = 'application/json; charset=UTF-8';
        fetchArgs.body = JSON.stringify(body);
    }
    // TODO add a timeout (configurable)
    const response = await fetch(apiPath, fetchArgs); // this fetch might be throwing on 502 error code (when server is offline)
    if (!response.ok) {
        try {
            const data = response.json();
            throw data.code;
        } catch (e) {
            throw `${response.status}`;
        }
    }
    const data = response.status === 204 ? '' : response.json();
    if (validator != null && !await validator(data)) {
        throw 'clientValidator';
    }
    return data;
}

// fetch from the api, put fetch status and result in the store
export const apiToStore = (patch, storePath, method, endpoint, {onSuccess, ...args} = {}) => {
    let canceled = false;
    patch({[storePath]: {status: 'loading', started: Date.now()}});
    (async () => {
        try {
            const result = await api(method, endpoint, args);
            if (!canceled) {
                patch({[storePath]: {status: 'success', result, finished: Date.now()}});
                if (onSuccess) {
                    try {
                        await onSuccess(result);
                    } catch (e) {};
                }
            }
        } catch (error) {
            if (!canceled) {
                patch({[storePath]: {status: 'error', error: error.toString(), finished: Date.now()}});
            }
        }
    })();

    // return a function to "cancel" this request. It doesn't actually try to stop the network request,
    // but it does cancel all followup actions like updating the status in redux and calling onSuccess.
    return () => canceled = true;
};

const networkErrors = {
    internalServerError: "Networking error: Server said it failed.",
    unauthorized: "Networking error: Server said you're not authorized to do that.",
    clientValidator: "Networking error: Server's response doesn't look right.",
    notFound: "Networking Error: Requested data not found.",
    loginFail: "Incorrect username and/or password.",
}
networkErrors['400'] = networkErrors['requestValidation'];
networkErrors['403'] = networkErrors['unauthorized'];
networkErrors['404'] = networkErrors['notFound'];
networkErrors['500'] = networkErrors['internalServerError'];
networkErrors['responseStatusValidation'] = networkErrors['internalServerError'];
networkErrors['responseHeaderValidation'] = networkErrors['internalServerError'];
networkErrors['responseBodyValidation'] = networkErrors['internalServerError'];
const defaultNetworkError = "Network communication failed (cause unknown)";


const prettyNetworkError = ({error}) => {
    if (error === '500') {
        return 'Network error: Server failed (500)'; 
    }
}

export const NetworkErrorComponent = ({error, retry}) => (
    <div class={css.ApiLoadError}>
        <div class={css.ApiLoadErrorMessage}>{networkErrors[error] || defaultNetworkError}</div>
        <div class={css.ApiLoadRetry}>
            <button class={css.ApiLoadRetryButton} onClick={retry}>Retry</button>
        </div>
    </div>
);

// displays different things depending on fetch.status
// you can supply a component to display for any state, eg:
//     <SpinLoad success={() => <h1>yay!</h1>}/>
// states: idle, loading, success, error
export const SpinLoad = ({fetch, retry, ...statusComponents}) => {
    const status = fetch && fetch.status || 'idle';
    const StatusComponent = statusComponents[status];
    if (StatusComponent) {
        return <StatusComponent fetch={fetch} retry={retry}/>;
    } else if (status === 'idle') {
        return <></>
    } else if (status === 'error') {
        return <NetworkErrorComponent error={fetch.error} retry={retry}/>;
    } else {
        return <Spinner/>;
    }
};

// put this in a form as the submit button, and respond to form.onSubmit
export const SpinSubmit = ({fetch, label}) => {
    const status = fetch && fetch.status || 'idle';
    if (status === 'idle') {
        return (
            <input type="submit" class={css.section} value={label || "Submit"}/>
        );
    } else if (status === 'error') {
        return (
            <div class={css.ApiSubmitError}>
                <div class={css.ApiSubmitErrorMessage}>Network communication failed</div>
                <div class={css.ApiSubmitRetry}>
                    <input type="submit" class={css.section} value="Retry"/>
                </div>
            </div>
        );
    } else {
        return <Spinner/>;
    }
};
