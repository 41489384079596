import {useLayoutEffect, useEffect, useState} from 'preact/compat';
import jsCookies from 'js-cookie';
import Link, {Button} from './Link.js';
import css, {cssCode, gpx} from './css.js';
import {usePatch, useGetter, parseUrl} from './store.js';
import {api, apiToStore, SpinLoad} from './api.js';
import Iframe from './PreactIframe.js';
import Spinner from './Spinner.js';
import layouts, {P, H1, H2, Section, Subsection} from './layouts.js';
import {ROUND_START_S, unixTime, arrayRand, arrayRands} from './misc.js';

// helpers
const _ords = ['th', 'st', 'nd', 'rd']
const ords = (n) => _ords[n] || _ords[(n-20)%10] || 'th';
const i02 = (x) => x < 10 ? `0${x}` : `${x}`;
const formatMinutesSeconds = (s) => {
    if (s <=0) {
        return "00:00";
    }
    const minutes = Math.floor(s / 60);
    const seconds = Math.floor(s % 60);
    return `${i02(minutes)}:${i02(seconds)}`;

};
// help scale down a child to not be bigger than a parent
// requirements:
//     the outer element must not get smaller when the inner is set to position:absolute
// usage:
//     const [refs, style] = autoScaleHelper({hash: text, style: scale => ({fontSize: `${scale * 100}%`})});
//     return <div ref={(el) => refs.outer = el}><div ref={(el) => refs.inner = el} style={style}>{text}</div></div>;
// arguments:
//     hash: any value that changes when we need to re-measure
//     style: a function which takes a scale factor and returns a style object
//     max: (optional) maximum size of inner, eg pass 0.8 so inner will be at least 20% smaller than outer.
const autoScaleHelper = ({max = 1, hash = '', style} = {}) => {
    const [refs] = useState({});
    // use state of this component to keep track of whether we've rendered and measured yet
    const [[prevHash, scale], setScale] = useState([null, null]);
    useEffect(() => { // no idea why useLayoutEffect() doesn't work here. This seems like the exact situation that than hook is for
        let checks = 0;
        const check = () => {
            if (refs.inner == null || refs.outer == null || refs.outer.offsetWidth == 0 || refs.inner.offsetWidth == 0) {
                // this happens setimes in firefox. trigger re-check shortly. it's gotta give us refs and a layout some day
                if (checks < 100) {
                    checks += 1;
                    setTimeout(check, 10);
                } else {
                    // give up on measuring, tell caller to display it unscaled
                    setScale([hash, 1]);
                }
            } else {
                setScale([hash, Math.min(1, max * refs.outer.offsetWidth / refs.inner.offsetWidth)]);
            }
        };
        check();
    }, [hash]);

    if (scale === null || hash != prevHash) {
        // we haven't measured yet, so render at full size, and position absolute so it won't make the parent grow
        return [refs, {
            visibility: 'hidden',
            position: 'absolute',
            top: 'auto',
            right: 'auto',
            bottom: 0,
            left: 0,
            width: 'auto',
            height: 'auto',
        }];
    } else {
        // we have measured so render
        return [refs, style(scale)];
    }
};



export const Credits = () => <>
    <H1>Credits</H1>
    <P>GOTV Bingo is brought to you by <a href="https://www.plannedparenthoodaction.org/elections" target="_blank">Planned Parenthood Votes</a>.</P>
    <P>Gameplay and project management by <a href="https://tesacollective.com/" target="_blank">The TESA Collective</a>.</P>
    <P>Programming by <a href="https://jasonwoof.com/" target="_blank">Jason Woofenden</a>.</P>
    <P></P>
    <P>Paid for by Planned Parenthood Votes, 123 William Street, NY NY 10038. Not authorized by any candidate or candidate’s committee.</P>
</>;

export const Welcome = () => <>
    <layouts.Outer>
        <layouts.CenteredWhiteInner>
            <Section>
                <H1>Welcome!</H1>
                <P>Get Out The Vote Bingo is a game meant to spice up your phone banking and text banking! This modernized version of bingo allows you to have fun with a little healthy competition while getting out the vote.</P>
                <P><Button to="create">Create a new game</Button>{"   "}<Button to="join">Join an existing game</Button>{"   "}<Button to="instructions">Game rules</Button></P>
                <P>During Get Out The Vote Bingo, you’ll score one point every time you complete a box; and score five bonus points every time you complete a row (horizontal, vertical, and diagonal). When you’re done playing, When you’re done playing.</P>
                <P>But the most important rule of all is to go save democracy!</P>
                <P></P>
                <P>Paid for by Planned Parenthood Votes, 123 William Street, NY NY 10038. Not authorized by any candidate or candidate’s committee.</P>
            </Section>
        </layouts.CenteredWhiteInner>
        <NotPlayingBottomButtons/>
    </layouts.Outer>
</>;

export const randomId = () => `${Math.random()}`.replace('0.', '').substr(0, 10);
const tileLabels = {
    calls: [
        "Someone committed to voting!",
        "“Of course I’m voting!”",
        "“I love Planned Parenthood!”",
        "Already requested mail in ballot",
        "Committed to request mail in ballot",
        "Asks what absentee voting is",
        "Has already mailed in a ballot",
        "Wants to volunteer",
        "Asks how to donate",
        "Wrong number",
        "Is voting early",
        "Will drop off their ballot at a drop-off location",
        "Knows where their polling place is",
        "Committed to vote on election day",
        "Family member of the contact",
        "Poll worker",
        "Healthcare comes up",
        "“I’ll pray for you”",
        "Will not vote",
        "Will vote for the first time",
        "Wants to know how to request mail-in ballot",
        "Biden / Harris supporter",
        "Funny voicemail message",
        "Bad connection",
        "Asks you to talk to family",
        "Trump supporter",
        "Long talker / Rambler",
        "“Sorry I’m running out the door”",
        "Got hung up on",
        "Disconnected Number",
        "Talks about the debate",
        "“I already voted”",
        "“I can’t hear you”",
        "Eating dinner",
        "“Never call me again”",
        "Speaks a language I don’t speak",
        "Wants to talk policy",
        "“I vote by the person, not the party”",
        "Third party comes up",
        "“*#$&$(@*”",
        "Cranky person",
        "“I don’t vote”",
        "“That’s my personal business”",
        "Talk to a child",
        "Is a Republican",
        "Supports down ballot candidates",
        "Caller taking call while driving",
        "Not home",
        "Voicemail",
        "Voicemail box was not set up",
        "Mailbox was full",
        "Asks to be removed from the list",
        "Black Lives Matter",
        "Thanks you for the work you’re doing",
        "Business or office number",
        "Calls you back",
        "Excited to talk to you",
        "Convinced them to vote!",
        "Asks about yard signs",
        "Undecided",
        "Voting with friends / family",
        "Needs help finding polling location",
        "Needs a ride to the polls",
        "“How did you get my number?”",
        "“Can you call me back?”",
        "Crying baby in background",
        "Tells their friends to vote",
        "Is a grandparent",
        "Is a student",
        "Does not support Planned Parenthood",
        "Asks “who is this?”",
        "Talks about Supreme Court",
        "Someone sneezes",
        "Talks about climate change",
        "Your computer loses connection",
        "Their phone cuts out",
        "Discusses pay inequality",
        "Voter brings up economy",
        "You stumble on the script",
        "Pronounced voters’ name incorrectly",
        "Dog barking in background",
        "Sirens in background",
        "Forget name of the voter during call",
        "Called voter by wrong name",
        "Voter lives in your state",
        "Voter lives in your city",
        "Voter is a teacher",
        "Nervous about voting in the pandemic",
        "Has volunteered with PP in the past",
        "Has Voter ID question",
        "Tells you their personal story",
        "Completes ballot on phone",
        "Voter already contacted by PPVotes",
        "Get three answers in a row",
        "Convo is more than 5 minutes",
        "Phone never stops ringing",
        "Voter mentions former pres. Candidates",
        "“This year sucks!”",
        "Makes a pun",
        "Makes a joke",
        "Talks about a relative",
        "Talks about a spouse",
        "ANTIFA",
        "Mentions COVID-19",
        "Mentions wearing a mask",
        "Is a Democrat",
        "“How can I help?”",
        "Voter makes you laugh",
        "RBG",
        "“I’m a liberal”",
        "“I’m a progressive”",
        "“I’m a conservative”",
        "“I’m a libertarian”",
        "Education is discussed",
    ], texts: [
        "Someone committed to voting!",
        "“Of course I’m voting!”",
        "“I love Planned Parenthood!”",
        "Already requested mail-in ballot",
        "Committed to request mail-in ballot",
        "Asks what absentee voting is",
        "Has already mailed in a ballot",
        "Wants to volunteer",
        "Asks how to donate",
        "Wrong number",
        "Is voting early",
        "Will drop off their ballot at a drop-off location",
        "Knows where their polling place is",
        "Committed to vote on election day",
        "Poll worker",
        "Brings up healthcare",
        "“I’ll pray for you”",
        "Will not vote",
        "Will vote for the first time",
        "Is a healthcare worker",
        "“How do I request a mail ballot?”",
        "Biden / Harris supporter",
        "Asks you to talk to family",
        "Trump supporter",
        "Troll",
        "Already voted!",
        "“Thank you for what you’re doing”",
        "Never text again",
        "Wants to talk policy",
        "“*#$&$(@* off!”",
        "Cranky person",
        "“I don’t vote”",
        "“That’s my personal business”",
        "Is a Republican",
        "Supports down ballot candidates",
        "Asks to be removed from the list",
        "“I want to volunteer!”",
        "Thanks you for the work you’re doing",
        "Excited to talk to you",
        "Convinced them to vote!",
        "Asks about yard signs",
        "Undecided",
        "Voting with friends / family",
        "Needs help finding polling location",
        "Needs a ride to the polls",
        "“How did you get my number?”",
        "Unsubscribe",
        "Does not support Planned Parenthood",
        "Nervous about voting in the pandemic",
        "Has volunteered with PP in the past",
        "Has Voter ID question",
        "Tells your their personal story",
        "Is a Democrat",
        "Voter already contacted by PPVotes",
        "Get three answers in a row",
        "Conversation is more than 10 texts long",
        "Voter mentions former pres. Candidates",
        "Typo",
        "Thumbs up emoji",
        "Puking emoji",
        "Heart emoji",
        "Raised fist emoji",
        "Poop emoji",
        "Talks about Climate Change",
        "Talks about Supreme Court",
        "“How can I help?”",
        "Voter responds immediately",
        "Voter makes you laugh",
        "Voter responds with string of texts",
        "Wants you to call",
        "Sends string of emojis",
        "Third party comes up",
        "Black Lives Matter",
        "Wants to debate you",
        "Debates come up",
        "Voter wants to know Biden / Harris position",
        "Messing with you",
        "You change voter’s mind",
        "Has already donated",
        "Accidentally made a new friend",
        "Sends you a GIF",
        "Responds in another language",
        "Makes a pun",
        "Makes a joke",
        "Talks about a relative",
        "Talks about a spouse",
        "ANTIFA",
        "Mentions COVID-19",
        "Mentions wearing a mask",
        "Accidentally deleted message before sending",
        "Phone is connected to charger",
        "Voter is a teacher",
        "Voter is a student",
        "Voter is a grandparent",
        "Gun control comes up",
        "Voter brings up economy",
        "Stock market",
        "“I’m a liberal”",
        "“I’m a progressive”",
        "“I’m a conservative”",
        "RBG",
        "Education is discussed",
    ], debates: [
        '"Um"',
        "Awkward Pause",
        "Didn't answer the question",
        "Mentioned Planned Parenthood",
        "Dared to Dream",
        "Pointed at people",
        '"nuke-u-lar"',
        "Oxymoron",
        "Hyperbole",
        "Obtuse",
        "Oblong",
        "Obsequious",
        "Lies!",
        "dangling participle",
    ]
};

// window.testRands = () => {
//     const nums = [...Array(100).keys()];
//     const stats = nums.map(() => 0);
//     for (let x = 0; x < 1000000; ++x) {
//         const result = arrayRands(nums, 10);
//         for (let i = 0; i < result.length; i++) {
//             const res = result[i];
//             stats[res] += 1;
//         }
//     }
//     console.log(stats);
// }

export const newBoard = (type) => {
    const labels = arrayRands(tileLabels[type], 24);
    return ({
        "tile0":  {label: labels[0], scored: 0},
        "tile1":  {label: labels[1], scored: 0},
        "tile2":  {label: labels[2], scored: 0},
        "tile3":  {label: labels[3], scored: 0},
        "tile4":  {label: labels[4], scored: 0},
        "tile5":  {label: labels[5], scored: 0},
        "tile6":  {label: labels[6], scored: 0},
        "tile7":  {label: labels[7], scored: 0},
        "tile8":  {label: labels[8], scored: 0},
        "tile9":  {label: labels[9], scored: 0},
        "tile10": {label: labels[10], scored: 0},
        "tile11": {label: labels[11], scored: 0},
        "tile12": {label: "free space", scored: 1},
        "tile13": {label: labels[12], scored: 0},
        "tile14": {label: labels[13], scored: 0},
        "tile15": {label: labels[14], scored: 0},
        "tile16": {label: labels[15], scored: 0},
        "tile17": {label: labels[16], scored: 0},
        "tile18": {label: labels[17], scored: 0},
        "tile19": {label: labels[18], scored: 0},
        "tile20": {label: labels[19], scored: 0},
        "tile21": {label: labels[20], scored: 0},
        "tile22": {label: labels[21], scored: 0},
        "tile23": {label: labels[22], scored: 0},
        "tile24": {label: labels[23], scored: 0},
    });
}

export const createPlayer = () => {
    const gameType = 'calls'; // FIXME
    return {
        name: "", // arrayRand(names),
        score: 1,
        phase: 'setup',
    }
};
const createGame = () => ({
    id: randomId(),
    players: {
        [randomId()]: createPlayer(),
    },
    createdAt: Date.now() / 1000,
    roundStartsAt: 0,
    prevRoundAt: 0,
    roundWinnerId: 'n/a',
});

// spin while the game is being created (sent to the server)
export const Create = () => {
    const [patch, csrf, fetch, fetchNum] = usePatch('csrf', 'createGame', 'createGameFetchNum');
    // configure api call
    const apiCall = () => {
        const game = createGame();
        const gameId = game.id;
        const playerId = Object.keys(game.players)[0];
        jsCookies.set('playerId', playerId, {expires: 1}); // days
        const canceler = apiToStore(patch, 'createGame', 'POST', 'v1/logs', {csrf, body: {type: 'gamePatch', details: {gameId, patch: {game}}}, onSuccess: () => {
            patch({
                playerId,
                game,
                historyReplace: `game-${gameId}`,
            });
        }})
        // return a cleanup function (called before this is called again, and on component unmount)
        return canceler;
    };

    // call from hook so we can save state and for proper cleanup
    useLayoutEffect(apiCall, [fetchNum]);

    // retry must trigger useLayoutEffect again, so that the cancel function will get called all the times it should (eg when the user presses browser's back button)
    const retry = () => patch({createGameFetchNum: fetchNum + 1});

    return (
        <layouts.CenteredWhite>
            <header class={css.centeredWhiteHeadline}>Creating game...</header>
            <SpinLoad fetch={fetch || {status: 'loading'}} retry={retry}/>
        </layouts.CenteredWhite>
    );
}

export const Join = () => {
    const [patch, gameId, joinField] = usePatch('game.id', 'joinField');
    const fields = {};
    // default to the gameid of your last game
    useLayoutEffect(() => {
        if (gameId && !joinField) {
            patch({joinField: gameId});
        }
    }, []);
    const submit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const value = fields.code && fields.code.value.trim() || '';
        if (value != '') {
            const gameId = (value.indexOf('s=') !== -1) ? parseUrl(value).split('-')[1] : value;
            if (gameId) {
                patch({historyPush: `game-${gameId}`});
            }
        }
    }
    const input = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (fields.code) {
            patch({joinField: fields.code.value});
        }
    }
    return <>
        <layouts.Outer>
            <layouts.CenteredWhiteInner>
                <H1>Join an Existing Game</H1>
                <P>To join an existing game, just click the invite link.</P>
                <P>Or enter your game code: <form style={{display: 'inline'}} onSubmit={submit}><input type="text" autofocus value={joinField} ref={(el) => fields.code = el} onInput={input}/> <input type="submit" value="Join" class={css.button}/></form></P>
                <Section><P><Button to="">Back to home page</Button></P></Section>
            </layouts.CenteredWhiteInner>
            <NotPlayingBottomButtons/>
        </layouts.Outer>

    </>;
};

const CountDown = ({to, onExpire, class: className, label}) => {
    const [remaining, setRemaining] = useState(parseFloat(to) - unixTime());
    const [fired, setFired] = useState(false);
    useEffect(() => {
        const int = setInterval(() => {
            const remaining = to - unixTime();
            setRemaining(remaining);
            if (remaining <= 0 && !fired) {
                setFired(true);
                if (onExpire) {
                    onExpire();
                }
                clearInterval(int)
            }
        }, 1000);
        return () => clearInterval(int);
    }, [to]);
    return <div class={className || css.countDown}>{label ? label + ' ' : ''}{formatMinutesSeconds(remaining)}</div>;
}

const RoundStartCountdown = ({label}) => {
    const [patch, roundStartsAt, playerId] = usePatch('game.roundStartsAt', 'playerId');
    const onExpire = () => patch({[`game.players.${playerId}.phase`]: 'playing'});
    return <H2><CountDown to={roundStartsAt} onExpire={onExpire} label={label} class={css.roundStartCountdown}/></H2>
};

const ScoreBoardName = ({name, class: className, score}) => {
    const [refs, style] = autoScaleHelper({max: 0.94, hash: `${score.toString().length} ${name}`, style: scale => ({fontSize: `${scale * 100}%`})});
    return <div ref={(el) => refs.outer = el} class={className}><div ref={(el) => refs.inner = el} style={style} class={css.highScoreNameInner}>{name}</div></div>
};

const ScoreBoard = () => {
    const [, highScores] = usePatch('highScores');
    return <layouts.Scores>
        <div class={css.ScoreBoard}>
            <header class={css.ScoreBoardTitle}>Score Board</header>
            {
                highScores.map(({id, name, rank, score, bumped, isPlayer}) => (
                    <div key={id} class={css.highScore + (bumped ? ' ' + css.bumpedHighScore : '')}>
                        <div class={css.highScoreRank}>{rank + 1}<sup>{ords(rank + 1)}</sup></div>
                        <ScoreBoardName class={css.highScoreName + (isPlayer ? ' ' + css.highScoreNameMe : '')} name={name} score={score}/>
                        <div class={css.highScoreScore}>{score}</div>
                    </div>
                ))
            }
        </div>
    </layouts.Scores>;
};

// these are all the patterns that count as scoring your board (5-in-a-row)
const boardLines = [
    // rows
    [0, 1, 2, 3, 4],
    [5, 6, 7, 8, 9],
    [10, 11, 12, 13, 14],
    [15, 16, 17, 18, 19],
    [20, 21, 22, 23, 24],
    //columns
    [0, 5, 10, 15, 20],
    [1, 6, 11, 16, 21],
    [2, 7, 12, 17, 22],
    [3, 8, 13, 18, 23],
    [4, 9, 14, 19, 24],
    // diagonals
    [0, 6, 12, 18, 24],
    [4, 8, 12, 16, 20],
];

// num is the tile being clicked. Note that it's state hasn't updated yet, so it'll still be scored=0 in board
const checkScoreLine = (board, num, line) => {
    for (const i of line) {
        if (i != num && board[`tile${i}`].scored === 0) {
            return false;
        }
    }
    return true;
}
// num is the tile being clicked
// this is only called when adding a check mark, not when removing one.
const checkScoreLines = (num) => {
    const state = window.store.getState(); // FIXME useState()
    if (!(state.playerId && state.game.players && state.game.players[state.playerId] && state.game.players[state.playerId].board)) {
        return 0;
    }
    const board = state.game.players[state.playerId].board;

    // make a list of lines that intersect the clicked square
    const row = Math.floor(num / 5);
    const col = num % 5;
    const intersecting = [
        boardLines[row],
        boardLines[5 + col],
    ];
    if(row === col) {
        intersecting.push(boardLines[10]);
    }
    if (row === 4 - col) {
        intersecting.push(boardLines[11]);
    }

    // return the intersecting lines that are all checked
    return intersecting.filter(line => checkScoreLine(board, num, line));
}

const Scored = ({animating}) => {
    const classes = [css.Scored];
    if (animating) {
        classes.push(css.Animating);
    }
    return <div class={classes.join(' ')}/>;
};

const Tile = ({num}) => {
    const [patch, playerId] = usePatch('playerId');
    const tileKey = `game.players.${playerId}.board.tile${num}`;
    const scoreKey = `game.players.${playerId}.score`;
    const boardTypeKey = `game.players.${playerId}.boardType`;
    const animatingLinesKey = `game.players.${playerId}.board.animatingLines`;
    const [, gameId, score, csrf, tile, boardType, animatingLines] = usePatch('game.id', scoreKey, 'csrf', tileKey, boardTypeKey, animatingLinesKey);

    // figure out css classes and click handler
    const outerClasses = [css.tileBorder];
    const outerAttrs = {};
    if (num === 12) {
        outerClasses.push(css.tileFreeSpace);
    } else if (!animatingLines) {
        outerAttrs.onClick = (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (num !== 12) {
                const change = {};
                const localOnlyChange = {};
                if (tile.scored) { // if this tile was already checked before this click
                    change[scoreKey] = score - 1;
                    change[`${tileKey}.scored`] = 0;
                } else {
                    change[`${tileKey}.scored`] = 1;
                    const lines = checkScoreLines(num);
                    change[scoreKey] = score + 1 + 5 * lines.length;
                    if (lines.length) {
                        for (const line of lines) {
                            for (const i of line) {
                                change[`game.players.${playerId}.board.tile${i}.scored`] = 2;
                            }
                        }
                        change[`game.players.${playerId}.board.animatingLines`] = true;
                        setTimeout(() => {
                            // get a new board now
                            api('post', 'v1/logs', {csrf, body: {type: 'gamePatch', details: {gameId, patch: {[`game.players.${playerId}.board`]: newBoard(boardType)}}}});
                        }, 2500); // FIXME if they refresh during the animation they're borked
                        localOnlyChange[`game.players.${playerId}.board.animatingLinesTimer`] = true; // log locally _only_ (redux) that we already have a timer to end the animation and get a new board.
                    }
                }
                patch(change);
                api('post', 'v1/logs', {csrf, body: {type: 'gamePatch', details: {gameId, patch: change}}});
            }
        }
    }

    return <td>
        <div class={outerClasses.join(' ')} {...outerAttrs}>
            <div class={css.tileCenter}>
                {tile.scored ? <Scored animating={tile.scored === 2}/> : tile.label}
            </div>
        </div>
    </td>
};

const Board = () => (
    <table class={css.board}>
        <tr>
            <Tile num={ 0}/>
            <Tile num={ 1}/>
            <Tile num={ 2}/>
            <Tile num={ 3}/>
            <Tile num={ 4}/>
        </tr>
        <tr>
            <Tile num={ 5}/>
            <Tile num={ 6}/>
            <Tile num={ 7}/>
            <Tile num={ 8}/>
            <Tile num={ 9}/>
        </tr>
        <tr>
            <Tile num={10}/>
            <Tile num={11}/>
            <Tile num={12}/>
            <Tile num={13}/>
            <Tile num={14}/>
        </tr>
        <tr>
            <Tile num={15}/>
            <Tile num={16}/>
            <Tile num={17}/>
            <Tile num={18}/>
            <Tile num={19}/>
        </tr>
        <tr>
            <Tile num={20}/>
            <Tile num={21}/>
            <Tile num={22}/>
            <Tile num={23}/>
            <Tile num={24}/>
        </tr>
    </table>
);

const Setup = () => {
    const [, playerId, csrf, gameId, roundStartsAt, suggestedNickname] = usePatch('playerId', 'csrf', 'game.id', 'game.roundStartsAt', 'suggestedNickname');
    const playerKey = `game.players.${playerId}`;
    const nameKey = `${playerKey}.name`;
    const phaseKey = `${playerKey}.phase`;
    const [patch, playerName, phase] = usePatch(nameKey, phaseKey);
    const refs = {};
    const playerNameInput = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const change = {[nameKey]: e.target.value};
        patch(change);
        // don't show the server / others until they're done editing and pick a game type
        // api('post', 'v1/logs', {csrf, body: {type: 'gamePatch', details: {gameId, patch: change}}}).catch(() => {}); // FIXME maybe should wait for this
    }
    const playButton = (e, boardType) => {
        e.preventDefault();
        e.stopPropagation();
        const change = {
            // these go to redux and the server
            [`${playerKey}.boardType`]: boardType,
            [`${playerKey}.board`]: newBoard(boardType),
        };
        patch({...change, [`${playerKey}.phase`]: 'playing'});
        api('post', 'v1/logs', {csrf, body: {type: 'gamePatch', details: {gameId, patch: change}}}).catch(() => {}); // FIXME maybe should wait for this
    }
    const callsButton = (e) => playButton(e, 'calls');
    const textsButton = (e) => playButton(e, 'texts');
    // const debatesButton = (e) => playButton(e, 'debates');
    const nameDone = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const change = {
            [`${playerKey}.phase`]: 'setup2',
            [nameKey]: playerName.trim() || refs.name.placeholder,
        };
        patch(change);
        api('post', 'v1/logs', {csrf, body: {type: 'gamePatch', details: {gameId, patch: change}}}).catch(() => {}); // FIXME maybe should wait for this
    }
    return <>
        <layouts.Setup>
            {phase === 'setup' ? <>
                <form onSubmit={nameDone}>
                    <header class={css.setupHeadline}>Welcome!</header>
                    <div class={css.subsection}>
                        <label>
                            Choose a unique display name:<br/>
                            <input type="text" value={playerName} onInput={playerNameInput} autofocus maxlength="12" size="12" ref={(el) => refs.name = el} placeholder={suggestedNickname}/>
                        </label>
                    </div>
                    <div class={`${css.subsection} ${css.setupFinePrint}`}>Your display name will be shown to the other players in your game and stored in our logs.</div>
                    <div class={css.subsection}><input type="submit" value="Next" class={css.button}/></div>
                </form>
            </> : <>
                <header class={css.setupHeadline}>Hi {playerName}!</header>
                <div class={css.subsection}>What will you be doing?</div>
                <div class={css.subsection}><Button onClick={callsButton}>Making Calls!</Button></div>
                <div class={css.subsection}><Button onClick={textsButton}>Sending Texts!</Button></div>
                {/* <div class={css.subsection}><Button onClick={debatesButton}>Watching Debates!</Button></div> */}
            </>}
        </layouts.Setup>
        <BottomButtons/>
    </>
};


const SmallTile = ({player, num}) => {
    const [patch, scored] = usePatch(`game.players.${player}.board.tile${num}.scored`);
    const outerClasses = [css.smallTile];
    if (scored) {
        outerClasses.push(css.tileScored);
    }
    return <td>
        <div className={outerClasses.join(' ')}>
        </div>
    </td>
};

// scales name down to fit
const SmallBoardName = ({name}) => {
    const [refs, style] = autoScaleHelper({hash: name, max: 0.9, style: scale => ({fontSize: `${scale * 100}%`})});
    return <div class={css.smallBoardName} ref={(el) => refs.outer = el}>{" "}<div ref={(el) => refs.inner = el} class={css.smallBoardNameInner} style={style}>{name}</div></div>
}

const SmallBoard = ({player, style}) => {
    const [, name] = usePatch(`game.players.${player}.name`);
    return <div class={css.otherPlayer} style={style}>
        <table class={css.otherPlayerBoard} style={{width: style.width, height: style.width}}>
            <tr>
                <SmallTile player={player} num={ 0}/>
                <SmallTile player={player} num={ 1}/>
                <SmallTile player={player} num={ 2}/>
                <SmallTile player={player} num={ 3}/>
                <SmallTile player={player} num={ 4}/>
            </tr><tr>
                <SmallTile player={player} num={ 5}/>
                <SmallTile player={player} num={ 6}/>
                <SmallTile player={player} num={ 7}/>
                <SmallTile player={player} num={ 8}/>
                <SmallTile player={player} num={ 9}/>
            </tr><tr>
                <SmallTile player={player} num={10}/>
                <SmallTile player={player} num={11}/>
                <SmallTile player={player} num={12}/>
                <SmallTile player={player} num={13}/>
                <SmallTile player={player} num={14}/>
            </tr><tr>
                <SmallTile player={player} num={15}/>
                <SmallTile player={player} num={16}/>
                <SmallTile player={player} num={17}/>
                <SmallTile player={player} num={18}/>
                <SmallTile player={player} num={19}/>
            </tr><tr>
                <SmallTile player={player} num={20}/>
                <SmallTile player={player} num={21}/>
                <SmallTile player={player} num={22}/>
                <SmallTile player={player} num={23}/>
                <SmallTile player={player} num={24}/>
            </tr>
        </table>
        <SmallBoardName name={name} style={{width: style.width}}/>
    </div>;
};

// return number of columns that SmallBoards should be displayed in
// (there's always one more column than row)
const smallBoardsColumns = (count) => {
    if (count === 1) {
        return 1.5; // weird, but it works nicely :)
    } else if (count === 2) {
        return 2;
    } else if (count <= 6) {
        return 3;
    } else {
        return 4;
    }
};

const SmallBoards = () => {
    const [, smallBoardIds, gameId] = usePatch('smallBoardIds', 'game.id');
    if (smallBoardIds.length == 0) {
        return <layouts.PlayingAlone>
            <H1>Solo Play</H1>
            <P>Nobody else is connected to this game.</P>
            <P><Button to={`invite-${gameId}`}>Invite more players!</Button></P>
        </layouts.PlayingAlone>
    }
    const columns = smallBoardsColumns(smallBoardIds.length);
    const gaps = (columns - 1) * .2023;
    const columnWidth = (columns - gaps) / columns / columns;
    const columnWidthPct = `${columnWidth * 100}%`;
    const fontSize = `${columnWidth * 7}vw`;
    const width = gpx(.8 * css.smallBoardsWidth / columns);
    return <layouts.SmallBoards>
        {smallBoardIds.map((k, i) => {
            const column = i % columns
            const row = Math.floor(i / columns);
            const top = gpx(row * css.smallBoardsWidth / columns * 1.3);
            const left = gpx(column * css.smallBoardsWidth / columns);
            return <SmallBoard player={k} key={k} style={{top, left, width, fontSize}}/>;
        })}
    </layouts.SmallBoards>;
};

export const Instructions = () => {
    const [, [, urlGameId], reduxGameId] = usePatch('urlWords', 'game.id');
    const gameId = urlGameId || reduxGameId;
    return <>
        <layouts.Outer>
            <layouts.CenteredWhiteInner>
                <Subsection>
                    <div style={{width: '70%', marginRight: 'auto'}}>
                        <div style={{
                            width: '100%',
                            paddingTop: `${315/560 * 100}%`,
                            margin: '0.001px',
                            position: 'relative',
                        }}>
                            <iframe style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} src="https://www.youtube.com/embed/kJ3GjtxzQVM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </Subsection>
                <Subsection>
                    <H1>Rules Summary</H1>
                    <P>SCORE 1 POINT: each time you check a box. You can complete multiple boxes at once.</P>
                    <P>SCORE 5 POINTS: each time you complete a row (horizontal, vertical, or diagonal). The game will also create a fresh new board for you.</P>
                    <P>DONE PLAYING: When you’re done playing, click the DONE PLAYING button to see how many points you scored and what place you came in! You can always click return to game if you want to keep playing.</P>
                    {gameId ? <P><Button to={`game-${gameId}`}>Back to the game!</Button></P> : <></>}
                </Subsection>
                <Section>
                    <H1>Full Rules</H1>
                    <P>“Get Out The Vote (GOTV) Bingo” is played for as long as you want you make GOTV phone calls and texts. You’ll choose if you’re making calls or texts when you enter the game, and you’ll get different content depending on which you select.</P>
                    <P>1. As you make GOTV phone calls and texts, keep an eye on your GOTV Bingo Board! Whenever you have an interaction on your calls or texts that matches what’s on the board (such as “Democrat” or the voter says “I love Planned Parenthood!”), click on your corresponding bingo box.</P>
                    <P>2. You may click on more than one box at a time if you have multiple corresponding experiences (such as “Republican” and “Grumpy person”).</P>
                    <P>3. Whenever you check a box, you score 1 point!</P>
                    <P>4. Whenever you complete a row - vertical, horizontal, or diagonal - you score 5 bonus points!</P>
                    <P>5. Occasionally you may score multiple rows at once - getting you 10 or 15 points!</P>
                    <P>6. Whenever you complete a row, the game will generate a new, fresh bingo card for you.</P>
                    <P>7. Click the “DONE PLAYING” button whenever you want to finish up, and see what place you came in. </P>
                    <P>But most importantly… let’s get out the vote!</P>
                    <P>Rules questions? Email us: <a href={"mailto:contact" + '@' + "tesacollective.com"}>contact{'@'}tesacollective.com</a></P>
                </Section>
                <Section>
                    {gameId ?
                        <P><Button to={`game-${gameId}`}>Back to the game!</Button></P>
                    :
                        <P><Button to="">Back to home page</Button></P>
                    }
                </Section>
            </layouts.CenteredWhiteInner>
            {gameId ? <BottomButtons/> : <NotPlayingBottomButtons/>}
        </layouts.Outer>
    </>;
};

export const Missing = () => {
    const [, [, urlGameId], reduxGameId] = usePatch('urlWords', 'game.id');
    const gameId = urlGameId || reduxGameId;
    return <>
        <layouts.Outer>
            <layouts.CenteredWhiteInner>
                <H1>Game Not Found</H1>
                <P>Oh Dear, we can't seem to find your game.</P>
                <P><Button to="">Start Over</Button></P>
            </layouts.CenteredWhiteInner>
            <NotPlayingBottomButtons/>
        </layouts.Outer>
    </>;
};

export const Invite = () => {
    const [, [, gameId]] = usePatch('urlWords');
    return <>
        <layouts.Outer>
            <layouts.CenteredWhiteInner>
                <Section>
                    <H1>Invite More Players!</H1>
                    <P>You can invite people to join your game at any time. All they need is the link. You can send them something like:</P>
                    <P><textarea class={css.inviteLetter} readonly>
                        Hi! Please join my game of Get Out The Vote Bingo here:
                        {"\n\n"}
                        {`${window.location}`.replace(`invite-${gameId}`, `game-${gameId}`)}
                        {"\n\n"}
                        Or you can join with the code {gameId} at gotvbingo.com
                    </textarea></P>
                </Section>
                <Section>
                    <P><Button to={`game-${gameId}`}>Back to the game!</Button></P>
                </Section>
            </layouts.CenteredWhiteInner>
            {gameId ? <BottomButtons/> : <NotPlayingBottomButtons/>}
        </layouts.Outer>
    </>;
};

const oxfordJoin = (a) => {
    if (a.length === 0) {
        return '';
    } else if (a.length === 1) {
        return a[0];
    } else if (a.length === 2) {
        return a.join(' and ');
    } else {
        return a.slice(0, a.length - 1).join(', ') + ', and ' + a[a.length -1];
    }
}

export const Lobby = () => {
    const [patch, [, gameId], players, roundStartsAt, csrf] = usePatch('urlWords', 'lobbyPlayers', 'game.roundStartsAt', 'csrf');
    // configure api call to start the game
    const apiCall = () => {
        apiToStore(patch, 'startGame', 'POST', 'v1/logs', {csrf, body: {type: 'gamePatch', details: {gameId, patch: {
            "game.roundStartsAt": unixTime() + ROUND_START_S,
            "game.prevRoundAt": roundStartsAt,
        }}}})
    };
    const InviteButton = () => (<Button to={`invite-${gameId}`}>Invite more players</Button>);
    const StartButton = () => (<Button to={`invite-${gameId}`} onClick={apiCall}>Start the game</Button>);
    const StartButtonOrCountdown = () => {
        if (roundStartsAt === 0) {
            return <StartButton/>
        } else {
            return <RoundStartCountdown label="Get ready! The game starts in:"/>
        }
    }

    return <>
        <layouts.Outer>
            <layouts.CenteredWhiteInner>
                <H1>Game Lobby</H1>
                <P>(A place to hang out while players arrive.)</P>
                <P>Connected players: {players.length === 1 ? "just you" : oxfordJoin(players.map(({name}) => name))}.{"   "}<InviteButton/></P>
                <P><StartButtonOrCountdown/></P>
            </layouts.CenteredWhiteInner>
            <BottomButtons/>
        </layouts.Outer>
    </>;
};

export const CreditsPage = () => <>
    <layouts.Outer>
        <layouts.CenteredWhiteInner>
            <Credits/>
        </layouts.CenteredWhiteInner>
        <NotPlayingBottomButtons/>
    </layouts.Outer>
</>;

export const GameOver = () => {
    const [, [slug, gameId], gameOverHighScores] = usePatch('urlWords', 'gameOverHighScores');
    return <>
        <layouts.Outer>
            <layouts.CenteredWhiteInner>
                <Section>
                    <H1>Thanks for Playing!</H1>
                    {gameOverHighScores.length ?
                        <P>
                            {gameOverHighScores.map(({id, names, rank, score, isPlayer}) => (
                                <div key={id}>
                                    {rank + 1}<sup>{ords(rank + 1)}</sup> place:{" "}
                                    {isPlayer ? (
                                        names.length === 0 ? (
                                            <span class={css.you}>You</span>
                                        ) : (
                                            <><span class={css.you}>You</span>{oxfordJoin(['', ...names])}</>
                                        )
                                    ) : (
                                        oxfordJoin(names)
                                    )} with {score} point{score === 1 ? '' : 's'}!
                                </div>
                            ))}
                        </P>
                    : null}
                    <P>Changed your mind about leaving the game? You can still <Button to={`game-${gameId}`}>rejoin the game!</Button></P>
                </Section>
                <Section>
                    <H1>Keep Up the Get Out The Vote Work</H1>
                    <P><a href="https://www.weareplannedparenthoodvotes.org/onlineactions/meGXxGaIBUS46t9t7ZDhIw2?sourceid=1009459" target="_blank">Donate to Planned Parenthood Votes</a></P>
                    <P><a href="https://act.plannedparenthoodaction.org/events?filter%5Bregions%5D=%5B38547%5D&amp;page=1">To volunteer sign up here</a> OR text 'GO' to 22422</P>
                    <P><a href="https://www.plannedparenthoodaction.org/email" target="_blank">Sign up for Planned Parenthood Votes newsletter</a></P>
                    <P><Button to="create">Create a new GOTV Bingo Game and recruit your friends!</Button></P>
                </Section>
                <Section>
                    <Credits/>
                </Section>
            </layouts.CenteredWhiteInner>
            <NotPlayingBottomButtons/>
        </layouts.Outer>
    </>;
};

// const BottomButton = ({label, onClick}) => <a class={css.bottomButton} href="#" onClick={e => {e.stopPropagation(); e.preventDefault(); onClick(e)}}><div class={css.bottomButtonInner}>{label}</div></a>;
const BottomButton = ({label, onClick, to, children}) => <Button className={css.bottomButton} innerClass={css.bottomButtonInner} onClick={onClick} to={to} label={label}>{children}</Button>;


const NotPlayingBottomButtons = () => (
    <layouts.BottomButtons>
        <Button class={css.ppLogoButton} innerClass={css.ppLogoButtonInner} href="https://www.plannedparenthoodaction.org/elections" target="_blank"/>
        <BottomButton to="">HOME</BottomButton>
        <BottomButton to="credits">CREDITS</BottomButton>
    </layouts.BottomButtons>
);
const isGameIdFormatRegex = /^[0-9]{10}$/;
const isGameIdFormat = (thing) => ((typeof thing === 'string') && isGameIdFormatRegex.test(thing));
const BottomButtons = () => {
    const [patch, reduxGameId, [, urlGameId]] = usePatch('game.id', 'urlWords');
    const gameId = reduxGameId || urlGameId;
    if (isGameIdFormat(gameId)) {
        return <layouts.BottomButtons>
            <BottomButton to={`invite-${gameId}`}>INVITE</BottomButton>
            <BottomButton to={`done-${gameId}`}>DONE PLAYING</BottomButton>
            <BottomButton to={`instructions-${gameId}`}>GAME RULES</BottomButton>
        </layouts.BottomButtons>;
    } else {
        return <NotPlayingBottomButtons/>
    }
};

export default () => {
    const [patch, [slug, gameId], reduxGameId, playerId, csrf, addPlayer, roundStartsAt]
        = usePatch('urlWords', 'game.id', 'playerId', 'csrf', 'addPlayer', 'game.roundStartsAt');
    const [, playerPhase, animatingLinesTimer] = usePatch(`game.players.${playerId}.phase`, `game.players.${playerId}.board.animatingLinesTimer`);
    const loaded = gameId === reduxGameId;
    const playerExists = playerPhase != null;
    const now = unixTime();

    // configure api call to add us as a new player into the game
    const apiCall = () => {
        const player = createPlayer();
        apiToStore(patch, 'addPlayer', 'POST', 'v1/logs', {csrf, body: {type: 'gamePatch', details: {gameId, patch: {
            [`game.players.${playerId}`]: player,
        }}}})
    };

    useEffect(() => {
        let ws;
        let done = false;
        // wrap this in a function so we can do it again after disconnects
        const createAndUseWebsocket = () => {
            window.jws = ws = new WebSocket(`${process.env.WS_URL}game-${gameId}`);
            ws.onclose = () => {
                // automatically reconnect later unless this component has been killed
                if (!done) {
                    setTimeout(() => {
                        if (!done) {
                            createAndUseWebsocket();
                        }
                    }, 10000)
                }
            }
            ws.onmessage = (messageTxt) => {
                try {
                    const message = JSON.parse(messageTxt.data);
                    if (message.patch) {
                        const update = {...message.patch};
                        // is this message creating the player?
                        const cookiePlayerId = jsCookies.get('playerId');
                        if ((playerId || cookiePlayerId) && (
                            (message.patch.game?.players && message.patch.game.players[cookiePlayerId || cookiePlayerId])
                            || message.patch[`game.players.${playerId || cookiePlayerId}`]
                        )) {
                            // the server just sent us our player.

                            // figure out which playerId is in the game
                            let correctPlayerId;
                            if (playerId != cookiePlayerId) {
                                if (message.patch[`game.players.${playerId}`] || (message.patch.game && message.patch.game.players[playerId])) {
                                    correctPlayerId = playerId;
                                    jsCookies.set('playerId', playerId, {expires: 1}); // days
                                } else {
                                    correctPlayerId = cookiePlayerId;
                                    update.playerId = cookiePlayerId;
                                }
                            } else {
                                correctPlayerId = playerId;
                            }

                            // determine our phase
                            const player = message.patch[`game.players.${correctPlayerId}`] || message.patch.game.players[correctPlayerId];
                            if (!player.name) {
                                update[`game.players.${correctPlayerId}.phase`] = 'setup';
                            } else if (!player.board) {
                                update[`game.players.${correctPlayerId}.phase`] = 'setup2';
                            } else {
                                update[`game.players.${correctPlayerId}.phase`] = 'playing';
                            }

                            // if there's a blinking 5-in-a-row and we don't have a timer for it, get a new board now
                            if (player.board?.animatingLines && !animatingLinesTimer) {
                                // get a new board now
                                const change = {[`game.players.${correctPlayerId}.board`]: newBoard(player.boardType)};
                                api('post', 'v1/logs', {csrf, body: {type: 'gamePatch', details: {gameId, patch: change}}});
                            }
                        }
                        // is this message a game stat that we're not in?
                        if (
                            message.patch.game && (
                                !(playerId || cookiePlayerId)
                                || ((playerId || cookiePlayerId) && !message.patch.game.players[playerId || cookiePlayerId])
                        )) {
                            // we just receieved the game state, and we're not it
                            // create/save a playerId for ourselves if needed
                            if (!playerId) {
                                // we need to set the playerId before the api call, because it saves the new player under this id
                                update.playerId = randomId();
                                jsCookies.set('playerId', update.playerId, {expires: 1}); // days
                            }
                            // schedule an api call (for after our id is saved) to add us to the game
                            update.addPlayer = {status: 'idle'}; // trigger apiCall later
                        }
                        patch(update);
                    } else if (message.error) {
                        patch({historyPush: `missing-${gameId}`});
                    }
                } catch (e) {
                    console.log('error processing ws message from server', e);
                }
            };
        }
        createAndUseWebsocket();
        // return cleanup function
        return () => {
            done = true;
            ws.close();
        }
    }, []); // only call this after the first render, call the cleanup function when this component dies

    useEffect(() => {
        if (loaded && !playerExists && (addPlayer == null || addPlayer.status === 'idle')) {
            apiCall()
        }
    });

    const retry = () => patch({addPlayer: {status: 'idle'}});

    if (!loaded) {
        return <layouts.Outer>
            <layouts.CenteredWhiteInner>
                <header class={css.centeredWhiteHeadline}>Loading Game...</header>
                <Spinner/>
            </layouts.CenteredWhiteInner>
            <NotPlayingBottomButtons/>
        </layouts.Outer>;
    } else if (!playerId || !playerExists) {
        return <layouts.Outer>
            <layouts.CenteredWhiteInner>
                <header class={css.centeredWhiteHeadline}>Adding you to the game...</header>
                <SpinLoad fetch={addPlayer} retry={retry}/>
            </layouts.CenteredWhiteInner>
            <NotPlayingBottomButtons/>
        </layouts.Outer>;
    } else if (slug === 'done') {
        return <GameOver/>;
    } else if (playerPhase === 'playing' && (roundStartsAt === 0 || now < roundStartsAt)) {
        return <Lobby/>
    } else {
        // show the high scores during setup so they can see their display name appear in the high scores as they type it
        return (
            <layouts.Outer>
                <ScoreBoard/>
                { playerPhase === 'playing' ? <>
                    <Board/>
                    <SmallBoards/>
                    <BottomButtons/>
                </> :
                    <Setup/>
                }
            </layouts.Outer>
        );
    }
};