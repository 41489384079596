import {usePatch, encodeUrl} from './store.js';
import css from './css.js';

export default ({to, action, children, history = 'push'}) => {
    const patch = usePatch();
    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (action) {
            action();
        } else {
            patch({[history === 'replace' ? 'historyReplace' : 'historyPush']: to});
        }
        return;
    };
    return <a href={encodeUrl(to)} onClick={onClick}>{children}</a>
}

export const Button = ({children, className, innerClass, to, href, target, onClick, history}) => {
    const patch = usePatch();
    const attrs = {class: innerClass || className || css.button};
    const outerAttrs = innerClass == null ? attrs : {class: className};
    outerAttrs.href = href || (to != null ? encodeUrl(to) : '#');
    if (target) {
        outerAttrs.target = target;
    }
    outerAttrs.onClick = e => {
        if (onClick == null && target === '_blank') {
            // don't meddle with perfection
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        if (onClick != null) {
            onClick(e);
        } else {
            patch({[history === 'replace' ? 'historyReplace' : 'historyPush']: to})
        }
    };
    const inner = <a {...attrs}>{children}</a>;
    if (innerClass == null) {
        return inner;
    } else {
        return <a {...outerAttrs}>{inner}</a>;
    }
}
