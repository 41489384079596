import {createStore as createReduxStore} from 'redux';
import {useSelector, useDispatch, useStore} from 'react-redux';
import {roleToCapabilities} from './auth.js';
import {ROUND_START_S, unixTime, arrayRand, arrayRands} from './misc.js';


// url formatting
export const encodeUrl = (to) => `?s=${encodeURIComponent(to)}`;
export const parseUrl = (url) => decodeURIComponent((url.split('s=')[1] || '').split(/[&#]/)[0] || '');
const canonicalizeUrl = (url) => parseUrl(encodeUrl(url));

// support path expressions like "board.tile.label"
const defaultGetter = (state, path) => {
    const pathSegments = path.split('.');
    let cur = state;
    for (const segment of pathSegments) {
        const val = cur[segment];
        if (val == null) {
            return val;
        }
        cur = val;
    }
    return cur;
};
const useFetch = (state, path) => defaultGetter(state, path) || {status: 'idle'};
const orZeroGetter = (state, path) => defaultGetter(state, path) || 0;

const playerKeys = (state) => {
    // get all players
    const players = defaultGetter(state, 'game.players');
    // filter to those who have finished setup1
    return (
        Object.entries(players)
        .filter(([id, player]) => player && player.name != null && player.name != '')
        .map(([id, player]) => id)
    );
};

const smallBoardIds = (state) => {
    // get all players
    const players = defaultGetter(state, 'game.players');
    const playerId = defaultGetter(state, 'playerId');
    // filter to those who have finished setup and aren't the player
    return (
        Object.entries(players)
        .filter(([id, player]) => player && id !== playerId && player.name != null && player.name != '' && player.boardType != '')
        .sort((a, b) => b[1].score - a[1].score)
        .slice(0, 12)
        .map(([id, player]) => id)
    );
};


// returns -1 when the player isn't in the list (eg because they don't have a name yet)
const setRanksReturnMine = (playerId, rankedPlayers) => {
    let ret = -1;
    for (let i = 0; i < rankedPlayers.length; i++) {
        const player = rankedPlayers[i];
        player.rank = i;
        if (player.id === playerId) {
            player.isPlayer = true;
            ret = player.rank;
        }
    }
    return ret;
}

const highScoresHelper = (state) => {
    const scores = [];
    const players = defaultGetter(state, 'game.players');
    const playerId = defaultGetter(state, 'playerId');
    // make a list of all the players and their scores
    for (const [id, {name, score}] of Object.entries(players)) {
        if (name) {
            scores.push({id, name, score});
        }
    }
    // sort
    scores.sort((a, b) => b.score - a.score);

    // set ranks
    const rank = setRanksReturnMine(playerId, scores);

    // return the useful bits
    return {scores: scores, rank};
}

// (with names)
const allScores = (state) => {
    const {scores} = highScoresHelper(state);
    return scores;
};

// for score board (while playing)
const highScores = (state) => {
    let {scores, rank} = highScoresHelper(state);

    // if we're on the list, but not top 5, cheat up to 5th so our score is visible
    if (rank !== -1) {
        // limit results to 5
        if (rank >= 5) {
            // if player would not be on the score board at all, put them last instead
            scores[rank].bumped = true;
            scores[rank].rank = rank;
            scores = [...scores.slice(0, 4), scores[rank]];
        }
    }
    // only show 5
    if (scores.length > 5) {
        scores = scores.slice(0, 5);
    }

    return scores;
};

// these are merged where the scores match, and don't include any with score=1
const gameOverHighScores = (state) => {
    let {scores} = highScoresHelper(state);

    // remove people who didn't get any points
    scores = scores.filter(s => s.score > 1);
    if (scores.length === 0) {
        return scores;
    }

    // merge people who tied
    scores = scores.map(({name, score, isPlayer}) => ({names: isPlayer ? [] : [name], isPlayer, score}));
    let i = 1;
    while (i < scores.length) {
        const prev = scores[i - 1];
        const cur = scores[i];
        if (prev.score === cur.score) {
            if (cur.isPlayer) {
                prev.isPlayer = true;
            } else {
                prev.names.push(cur.names[0]);
            }
            scores.splice(i, 1);
        } else {
            i += 1;
        }
    }

    // set the ranks (after merging ties)
    for (let i = 0; i < scores.length; i++) {
        const s = scores[i];
        s.rank = i;
    }

    return scores;
};

// also adds .isPlayer
const lobbyPlayers = (state) => {
    const ret = [];
    const players = defaultGetter(state, 'game.players');
    const playerId = defaultGetter(state, 'playerId');
    // make a list of all the players and their scores
    for (const [id, {name, score}] of Object.entries(players)) {
        if (name) {
            ret.push({id, name, isPlayer: id === playerId});
        }
    }
    return ret;
}

// each usePatch() path maps to one of these or defaultGetter above
const customGetters = {
    session: (state) => {
        // find session variables from the api calls they can come from
        const sources = [
            useFetch(state, 'whoami'),
            useFetch(state, 'login'),
            useFetch(state, 'register'),
        ].filter(fetch => fetch && fetch.status === 'success');
        sources.sort((a, b) => (b.finished || 0) - (a.finished || 0)); // most recent first
        const ret = sources.reduce((acc, source) => {
            for (const prop of ['csrf', 'id', 'name', 'role']) {
                if (source.result && acc[prop] == null && source.result[prop] != null) {
                    acc[prop] = source.result[prop]
                }
            }
            return acc;
        }, {});
        ret.can = (ret.role && roleToCapabilities[ret.role]) || {};
        return ret;
    },
    createGameFetchNum: (state) => orZeroGetter(state, 'createGameFetchNum'),
    urlWords: (state) => (defaultGetter(state, 'url') || '').split('-'),
    slug: (state) => customGetters.urlWords(state)[0],
    sessionCapabilities: (state) => customGetters.session(state).capabilities,
    csrf: (state) => customGetters.session(state).csrf,
    playerKeys,
    smallBoardIds,
    highScores,
    allScores,
    lobbyPlayers,
    gameOverHighScores,
    // default: defaultGetter
};
const get = (state, path) => (customGetters[path] || defaultGetter)(state, path);

const historyableState = (state) => ({...state, login: undefined, whoami: undefined, register: undefined});

// fields that have custom setters (reducers)
const historySetter = (historyMethod, state, url) => {
    const parsed = canonicalizeUrl(url);
    if (state.url !== parsed) {
        const newState = {...state, url: parsed}
        window.history[historyMethod](historyableState(newState), '', parsed === '' ? './' : encodeUrl(url));
        return newState;
    } else {
        return state;
    }
}
const setters = {
    historyPush: (state, k, url) => historySetter('pushState', state, url),
    historyReplace: (state, k, url) => historySetter('replaceState', state, url),
    historyGo: (state, k, steps) => {
        window.history.go(steps);
        return state;
    },
    // default: defaultSetter
};
const defaultSetter = (state, k, v) => {
    const pathSegments = k.split('.');
    const finalPathSegment = pathSegments.pop();
    const parents = []; // [..., [key2, object2], [key1, object1]]. object1[key1] === object2
    let cur = state
    for (const segment of pathSegments) {
        const inner = cur[segment];
        if (inner == null) {
            throw new Error(`Store's default setter can't find segment "${segment}" in key path "${k}". (You can't set a key in a nonexistent parent.)`);
        }
        parents.unshift([segment, cur]);
        cur = inner;
    }
    // all parents found

    // does this set() change the value?
    if (cur[finalPathSegment] === v) {
        return state; // no change
    } else {
        // copy all enclosing objects so redux can tell there was a change
        return parents.reduce((acc, [key, parent]) => {
            return {...parent, [key]: acc};
        }, {...cur, [finalPathSegment]: v});
    }
};



export const stateFromUrl = () => {
    const state = {};
    state.url = parseUrl(`${window.location}`);
    const [slug, ...args] = state.url.split('-');

    // debugging
    // if (window.location.hash != '' && window.location.hash != '#') {
    //     try {
    //         const hash = JSON.parse(atob(('' + window.location.hash).substr(1)));
    //         for (const [k, v] of Object.entries(hash)) {
    //             state[k] = v;
    //         }
    //     } catch (e) {
    //     }
    // }

    return state;
};

const nnWords = ['Pan', 'Car', 'Pal', 'Guf', 'Wow', 'Pup', 'Jam', 'Pow', 'Kor', 'Tor', 'Mak'];
const newNickname = () => arrayRand(nnWords) + arrayRand(nnWords);

const defaultState = () => {
    return {suggestedNickname: newNickname(), ...stateFromUrl()}
}

const reducer = (old_state = defaultState, {type, patch}) => {
    let state = old_state === defaultState ? defaultState() : old_state;
    if (type === 'patch' && patch) {
        for (const [k, v] of Object.entries(patch)) {
            state = (setters[k] || defaultSetter)(state, k, v);
        }
    }
    return state;
};

// wrapper for react-redux's useDispatch() that wraps it with a type="patch"
// usage:
//     const patch = usePatch();
//     const [patch, step, pageCount] = usePatch('step', 'pageCount'); // returns an array when you pass args
//     patch({step: step + 1, pageCount: pageCount + 2});
export const usePatch = (...paths) => {
    const dispatch = useDispatch();
    const patch = (args) => dispatch({type: 'patch', patch: args});
    if (paths.length === 0) {
        return patch;
    } else {
        return [patch, ...paths.map(path => useSelector(state => get(state, path)))];
    }
};



// usage:    ReactDOM.render(<Provider store={store()}><App /></Provider>, domParent);
export const createStore = () => {
    const store = createReduxStore(reducer);

    // this triggers window.popstate. set via window.history.replaceState() or just don't do it
    // store.subscribe(() => {
    //     window.location.hash = btoa(JSON.stringify(store.getState()));
    // });

    // debug? this updates the state object in window.history every time the state changes
    // store.subscribe(() => {
    //     const state = store.getState();
    //     window.history.replaceState(historyableState(state), '', state.url === '' ? './' : encodeUrl(state.url));
    // });
    return store;
}

// this doesn't cause your component to rerender
// but it uses context, so you have to call it top-level
export const useGetter = () => {
    const store = useStore();
    return (path) => get(store.getState(), path);
}