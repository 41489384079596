import Link from './Link.js';
import css, {cssCode} from './css.js';
import Iframe from './PreactIframe.js';

const layouts = {
    Outer: ({children}) => (
        <section class={css.gameOuterOuter}>
            <div class={css.gameOuter}>
                <Iframe class={css.game}>
                    <style>{cssCode} {"html,body{margin: 0; padding: 0; height: 100%; overflow: hidden;}"}</style>
                    <div class={css.gameBackdrop}>
                        <div class={css.gameBackdropInner}>
                            <div class={css.gameBackdropInnerInner}>
                                <layouts.GameTitle>Get Out The Vote Bingo</layouts.GameTitle>
                                {children}
                            </div>
                            <div class={css.gameBackdropForeground}/>
                        </div>
                    </div>
                </Iframe>
            </div>
        </section>
    ),
    CenteredWhiteInner: ({children}) => (
        <section class={css.layoutCenteredWhite}>
            <div class={css.layoutCenteredWhiteInner}>
                {children}
            </div>
        </section>
    ),
    CenteredWhite: ({children}) => (
        <layouts.Outer>
            <layouts.CenteredWhiteInner>{children}</layouts.CenteredWhiteInner>
        </layouts.Outer>
    ),
    H1: ({children}) => <header class={css.layoutH1}>{children}</header>,
    H2: ({children}) => <header class={css.layoutH2}>{children}</header>,
}; for (const slug of ['RoundEnd', 'Scores', 'Board', 'SmallBoards', 'GameTitle', 'BottomButtons', 'Setup', 'RoundTimer', 'BreakTimer', 'P', 'H1', 'H2', 'Section', 'Subsection', 'PlayingAlone']) {
    // it's handy to have these in here so we can 
    layouts[slug] = ({children}) => <section class={css[`layout${slug}`]}>{children}</section>
}

export default layouts;

export const P = layouts.P;
export const H1 = layouts.H1;
export const H2 = layouts.H2;
export const Section = layouts.Section;
export const Subsection = layouts.Subsection;