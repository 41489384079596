import {useLayoutEffect} from 'preact/compat';
import Link from './Link.js';
import {usePatch} from './store.js';
import {apiToStore, SpinLoad} from './api.js';
import css from './css.js';

const LoggedOut = () => <>
    <h1>Logged Out Successfully</h1>
    <div class={css.subsection}><Link to="chapter-00000000-0000-0000-0000-000000000000">Start the adventure</Link></div>
</>;


export default () => {
    const [patch, session, logout] = usePatch('session', 'logout');
    const fetch = () => apiToStore(patch, 'logout', 'POST', 'v1/logout', {csrf: session.csrf});
    const needFetch = session.can.logOut;
    useLayoutEffect(() => {
        if (needFetch) {
            // hit the logout endpoint, and on success invalidate login session
            fetch().then(() => patch({
                login: null,
                whoami: null,
                register: null,
            }));
        }
    }, [needFetch]); // fires when this _changes_
    return <>
        <div class={css.subsection}>csrf token: {session.csrf}</div>
        <SpinLoad fetch={logout} retry={fetch} success={LoggedOut}/>
    </>;
};
