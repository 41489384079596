export const roleToCapabilities = {
    admin: {
        logOut: true,
        admin: true,
        edit: true,
        ban: true,
    },
    author: {
        logOut: true,
        edit: true,
    },
};
