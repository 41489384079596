import {render} from 'preact';
import {Provider} from 'react-redux';
import {createStore, stateFromUrl} from './store.js';
import App from './App.js';
import css from './css.js';


const main = () => {
    // find where we should put the UI
    const outer = document.getElementById('react_outer').firstElementChild;

    // remove the loading spinner
    while (outer.firstChild) {
        outer.removeChild(outer.firstChild);
    }

    const store = createStore();

    // debugging
    window.store = store;
    //store.subscribe(state => console.log(state));
    store.subscribe(() => {
        const state = store.getState();
        //window.history.replaceState(state, '', `#${btoa(JSON.stringify(state))}`);
    });

    window.onpopstate = function(event) {
        // console.log('popstate', event)
        if (event.state) {
            store.dispatch({type: 'patch', patch: event.state});
        } else {
            store.dispatch({type: 'patch', patch: stateFromUrl()});
        }
    }

    document.body.className = css.mainBody;

    // render
    render(<Provider store={store}><App /></Provider>, outer);
}
main();
