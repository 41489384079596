// little stuff that isn't worth making into a whole module

const IS_PRODUCTION = process.env.MODE === 'production'; // webpack passes this ENV in and defaults it to production

export const ROUND_START_S = 10; // seconds countdown from clicking [start]

export const unixTime = () => Date.now() / 1000;

export const arrayRand = (a) => a[Math.floor(Math.random() * a.length)];
export const arrayRands = (a, count) => {
    if (a.length < count) {
        // there aren't enough elements in a to return count unique items, so just pull random
        const ret = [];
        while (ret.length < count) {
            ret.push(arrayRand(a));
        }
        return ret;
    } else {
        const array = a.slice(0);

        for (var i = 0; i < count; ++i) {
            const j = i + Math.floor(Math.random() * (a.length - i));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array.slice(0, count);
    }
};

// wrap your function so if it returns a value that is deep-compare-the-same as last time then it'll return the ===-the-same object
export const deepSameRet = (fn) => {
    let prev = null;
    let prevJson = 'null';
    return (...args) => {
        const latest = fn(...args);
        const latestJson = JSON.stringify(latest);
        if (latestJson !== prevJson) {
            prev = latest;
            prevJson = latestJson;
        }
        return prev;
    }
};